html,
body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#no-cursor {
  cursor: none;
}

#unreachable {
  cursor: none;
}

.quarterbox {
  width: 50%;
  height: 50%;
  outline: #b4b4b4 2px solid;
  float: left;
}

#box1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#box2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#box3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

#box4 {
  color: #f0f0f0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
